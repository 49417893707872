import React, { useState, useRef, useCallback } from 'react';
import classNames from 'classnames';
import { Formik, Form, FastField, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Swal from 'sweetalert2';
import Attachment from 'assets/icons/attachment.svg';
import Mail from 'assets/icons/icon-mail.svg';
import { themeColor } from 'data/config';

import * as styles from 'styles/contact-form.module.scss';
import CheckmarkIcon from 'assets/icons/checkmark.svg';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const urls = {
  dev: 'http://localhost:4321/',
  prod: '/mailing/',
};

const ContactForm = ({ data }) => {
  // const { setFieldValue, isSubmitting, values, errors, touched, setFieldTouched, data } = props;
  const cms = data.datoCmsContact;
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [file, setFile] = useState(null);
  const fileInput = useRef(null);
  const handleFileInput = (event) => {
    setFile(event.currentTarget.files[0]);
  };

  const initialValues = {
    name: '',
    email: '',
    phone: '',
    projectType: [],
    attachmentFile: null,
    gdpr: false,
  };

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(
    async (cb) => {
      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return;
      }

      const token = await executeRecaptcha('formSubmit');
      cb(token);
    },
    [executeRecaptcha]
  );

  const validationSchema = () =>
    Yup.object().shape({
      name: Yup.string().required('Name field is required'),
      email: Yup.string().email('Invalid email').required('Email field is required'),
      phone: Yup.string()
        // eslint-disable-next-line no-useless-escape
        .matches(/[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g, 'Invalid phone number'),
      projectType: Yup.array().of(Yup.string()),
      attachmentFile: Yup.mixed()
        .test('fileSize', 'The file is too large', (value) =>
          value ? value.size <= 1024000 * 5 : true
        )
        .test('fileType', 'We are accepting formats: .jpeg, .png, .pdf and .doc', (value) =>
          value
            ? ['image/jpeg', 'image/png', 'application/pdf', 'application/msword'].includes(
                value.type
              )
            : true
        ),
      gdpr: Yup.bool()
        .required('GDPR agreement is required')
        .oneOf([true], 'GDPR agreement is required'),
    });

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    const formData = new FormData();

    Object.keys({
      ...values,
      projectType: values.projectType.join(', '),
    }).forEach((valueKey) => {
      formData.append(valueKey, values[valueKey]);
    });
    handleReCaptchaVerify((token) => {
      formData.append('token', token);

      axios({
        method: 'post',
        url: process.env.NODE_ENV === 'development' ? urls.dev : urls.prod,
        data: formData,
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
        .then((response) => {
          const {
            data: { success, error },
          } = response;
          if (success) {
            // console.log('submitted successfully');
            Swal.fire({
              title: 'Thank You!',
              text: 'Your message has been sent!',
              icon: 'success',
              confirmButtonColor: themeColor,
            }).then();
            setFile(null);
            resetForm({
              name: '',
              email: '',
              phone: '',
              projectType: [],
              attachmentFile: null,
              gdpr: false,
            });
          } else throw error;

          setSubmitting(false);
        })
        .catch((err) => {
          console.log('error: ', err);
          Swal.fire({
            title: 'Error',
            text: 'An error occured while submitting form. Please try again later.',
            icon: 'error',
            confirmButtonColor: themeColor,
          }).then();
          setSubmitting(false);
        });
    });
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({
        setFieldValue,
        isSubmitting,
        values,
        errors,
        touched,
        setFieldTouched,
        handleSubmit,
      }) => (
        <Form method="post" className={styles.contactForm} onSubmit={handleSubmit}>
          <div className={styles.fieldsWrapper}>
            <div className={classNames(styles.inputField, styles.textField)}>
              <FastField
                id="name"
                type="text"
                name="name"
                component="input"
                aria-label="name"
                placeholder="name"
                className="link" // to make active state for animated cursor
                error={touched.name && errors.name}
              />
              <label htmlFor="name">
                <span>{cms.fullName}</span>
                <div className={styles.bar} />
              </label>
              <ErrorMessage className={styles.error} component="div" name="name" />
            </div>
            <div className={classNames(styles.inputField, styles.textField)}>
              <FastField
                id="email"
                aria-label="email"
                component="input"
                type="email"
                name="email"
                placeholder="email"
                className="link" // to make active state for animated cursor
                error={touched.email && errors.email}
              />
              <label htmlFor="email">
                <span>{cms.email}</span>
                <div className={styles.bar} />
              </label>
              <ErrorMessage className={styles.error} component="div" name="email" />
            </div>
            <div className={classNames(styles.inputField, styles.textField)}>
              <FastField
                id="phone"
                aria-label="phone"
                component="input"
                type="tel"
                name="phone"
                placeholder="phone"
                className="link" // to make active state for animated cursor
                error={touched.phone && errors.phone}
              />
              <label htmlFor="phone">
                <span>{cms.phone}</span>
                <div className={styles.bar} />
              </label>
              <ErrorMessage className={styles.error} component="div" name="phone" />
            </div>
            <div className={classNames(styles.inputField, styles.textField, styles.fileField)}>
              <input
                id="attachmentFile"
                name="attachmentFile"
                type="file"
                placeholder="attachmentFile"
                ref={fileInput}
                className={classNames({
                  [`${styles.hasFile}`]: !!file,
                })}
                onChange={(event) => {
                  handleFileInput(event);
                  setFieldTouched('attachmentFile');
                  setFieldValue('attachmentFile', event.currentTarget.files[0]);
                }}
              />
              <label htmlFor="attachmentFile">
                <div className={classNames(styles.helperText, styles.top)}>
                  {cms.projectDetailsDescription}
                </div>
                <span title={cms.projectDetail}>{cms.projectDetail}</span>
                {file && <div className={styles.filename}>{file.name}</div>}
                <div className={styles.uploadButton}>
                  <Attachment />
                </div>
                <div className={styles.bar} />
                <div className={classNames(styles.helperText, styles.bottom)}>
                  {cms.projectDetailsOptional}
                </div>
              </label>
              <ErrorMessage className={styles.error} component="div" name="attachmentFile" />
            </div>
          </div>

          <div className={styles.row}>
            {cms.projectCategories && (
              <div className={classNames(styles.checkboxGroup)}>
                <h2>{cms.projectCategoriesTitle}</h2>
                <div className={styles.fieldWrapper}>
                  {cms.projectCategories.map(({ category: type }) => {
                    return (
                      <React.Fragment key={type}>
                        <FastField
                          as="input"
                          type="checkbox"
                          value={type}
                          checked={values.projectType.includes(type)}
                          multiple
                          id={`projectType_${type}`}
                          name="projectType"
                          className={styles.checkboxControl}
                        />
                        <label htmlFor={`projectType_${type}`}>
                          <div className={styles.labelText}>{type}</div>
                        </label>
                      </React.Fragment>
                    );
                  })}
                </div>
                <ErrorMessage className={styles.error} component="div" name="projectType" />
              </div>
            )}
            <div className={styles.column}>
              <div className={classNames(styles.inputField, styles.checkboxField)}>
                <FastField
                  component="input"
                  type="checkbox"
                  aria-label="gdpr"
                  id="gdpr"
                  name="gdpr"
                />
                <label htmlFor="gdpr">
                  <span className={styles.checkmark}>
                    <CheckmarkIcon />
                  </span>
                  <div dangerouslySetInnerHTML={{ __html: cms.privacyPolicyText }} />
                </label>
                <ErrorMessage className={styles.error} component="div" name="gdpr" />
              </div>
              <button type="submit" disabled={isSubmitting}>
                <Mail />
              </button>
              <div className={styles.nda} dangerouslySetInnerHTML={{ __html: cms.ndaText }} />
              {values.success && (
                <div className={styles.messageField}>Your message has been successfully sent!</div>
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ContactForm;
