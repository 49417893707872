import React from 'react';
import { graphql } from 'gatsby';
import * as styles from 'styles/contact-page.module.scss';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import ContactForm from '../components/ContactForm';

const ContactPage = (props) => {
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }, []);
  const { data } = props;
  return (
    <main className={styles.contactPage}>
      <div className="page-container">
        <h1>{data.datoCmsContact?.customTitle}</h1>
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.GATSBY_RECAPTCHA_PUBLIC_KEY}
          container={{
            parameters: {
              badge: 'bottomleft',
            },
          }}
        >
          <ContactForm {...props} />
        </GoogleReCaptchaProvider>
      </div>
    </main>
  );
};

export default ContactPage;

export const query = graphql`
  query ContactPageQuery($locale: String) {
    seoData: datoCmsSite(locale: { eq: $locale }) {
      globalSeo {
        siteName
        fallbackSeo {
          description
          title
          image {
            fluid(maxWidth: 760, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
    datoCmsContact {
      seo {
        metaTags {
          title
        }
      }
      projectDetailsOptional
      projectDetailsDescription
      projectDetail
      projectCategoriesTitle
      projectCategories {
        category
      }
      privacyPolicyText
      phone
      ndaText
      fullName
      email
      customTitle
    }
    datoCmsSite {
      locale
      locales
    }
    datoCmsHeader(locale: { eq: $locale }) {
      team {
        phone
        email
        photo {
          fluid(maxWidth: 100, imgixParams: { fm: "jpg", auto: "compress" }) {
            ...GatsbyDatoCmsFluid
          }
        }
      }
      menuText
      menu {
        link
        text
      }
    }
    datoCmsFooter(locale: { eq: $locale }) {
      locale
      reviewLabel
      reviewMedia {
        url
        alt
        fluid(maxWidth: 180, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      footerLinks {
        title
        link
      }
      reviewLink
    }
  }
`;
